import React, { useState, useEffect } from "react";
import { useStyles } from "../styles/spotPage";
import { Box, Divider, Typography } from "@mui/material";
import BullionStatsLogo from "../assets/bullionLogo.png";
import Marquee from "react-fast-marquee";
import axios from "axios";
import io from "socket.io-client";
const socket = io("https://testsocket.bullionview.com");
const host = process.env.REACT_APP_BACKEND_URL;
const adminId = process.env.REACT_APP_ADMIN_ID;

const SpotPage = ({ setIsMarketOpen }) => {
  const classes = useStyles();
  const [time, setTime] = useState(new Date());

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };
  // console.log(formatTime(time));
  const formatDay = (date) => {
    return date.toLocaleString([], { weekday: "long" });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000); // Update every minute (60000 milliseconds)

    return () => {
      clearInterval(interval);
    };
  }, []);

  setTimeout(function () {
    window.location.reload();
  }, 10 * 60 * 1000);

  useEffect(() => {
    // getSocketUrl();
    allCommodities();
    getSpread();
    // getRatio();
  }, []);

  const [socketUrl, setSocketUrl] = useState("");
  // const socket = io(socketUrl);
  const [news, setNews] = useState("");
  const [silver, setSilver] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [previousSilver, setPreviousSilver] = useState();

  const [gold, setGold] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [previousGold, setPreviousGold] = useState();

  useEffect(() => {
    socket.on("connect", () => {});
    socket.on("disconnect", () => {});
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err}`);
      console.log(`connect_error due to ${err.message}`);
    });
    socket.on("gold-rate-change", ({ data }) => {
      // console.log(data, "gold");
      setIsMarketOpen(data?.isMarketOpen);
      setPreviousGold(gold);
      setGold({
        ask: data?.ask,
        bid: data?.bid,
        highPrice: data?.highPrice,
        lowPrice: data?.lowPrice,
      });
      // console.log(gold, 'gold');
    });
    socket.on("silver-rate-change", ({ data }) => {
      // console.log(data, "silver" );
      setPreviousSilver(silver);
      setSilver({
        ask: data?.ask,
        bid: data?.bid,
        highPrice: data?.highPrice,
        lowPrice: data?.lowPrice,
      });
      // console.log(silver, 'silver' );
    });
  });

  const [commodities, setCommodities] = useState();

  const allCommodities = async () => {
    try {
      const response = await axios.get(
        `${host}/allCommodities/${adminId}`
      );
      setCommodities(response?.data?.commodities);
    } catch (error) {
      console.error(error);
    }
  };

  const getNews = async () => {
    try {
      const response = await axios.get(
        `${host}/allNewses/${adminId}`
      );

      const activeNews = response?.data
        ?.filter((nws) => nws?.isActive)
        ?.map((nws, index) => (
          <>
            {nws?.newsDetails}
            {index !== response?.data?.length - 1 && (
              <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
            )}
          </>
        ));

      setNews(activeNews);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  // Call getNews() every 10 minutes
  setInterval(getNews, 10 * 60 * 1000); // 10 minutes * 60 seconds * 1000 milliseconds

  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  const getSpread = async () => {
    try {
      const response = await axios.get(
        `${host}/getSpread/${adminId}`
      );
      setSpread(response?.data);
      //console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  function commodityCalculation(
    commodityPrice,
    premuimPerOunce,
    unit,
    purity,
    additionalCharge
  ) {
    const usdToAed = 3.674;
    return (
      (((commodityPrice + premuimPerOunce) * usdToAed) / 31.1035) *
        purity *
        unit +
      additionalCharge
    );
  }

  return (
    <>
      <Box className={classes.spotPage} component="section">
        <Box className={classes.spotPageLogoSection} component="section">
          <Box className={classes.spotPageLogoSectionLogo} component="article">
            <img
              style={{
                height: "9vh",
                padding: "0.5em 0",
                // width: "50vw",
              }}
              src={BullionStatsLogo}
              alt="Bullion_Stats_Logo"
            />
          </Box>

          <Box className={classes.spotPageLogoSectionDate} component="article">
            <Typography
              sx={{
                color: "white",
                fontSize: "2.8vh",
                fontWeight: "700",
                fontFamily: "Roboto",
                lineHeight: "0.9",
              }}
            >
              {formatTime(time)}
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontSize: "1.6vh",
                fontFamily: "Roboto",
                fontWeight: "500",
              }}
            >
              {formatDay(time)}
              {" - "}
              {formatDate(time)}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.spotPageSpotRateContainer} component="section">
          <Box className={classes.spotPageSpotRateSection} component="section">
            <Box
              className={classes.spotPageSpotRateSectionSpotRateHeader}
              component="article"
            >
              <Box
                className={
                  classes.spotPageSpotRateSectionSpotRateHeaderContents
                }
                component="article"
              >
                <Typography
                  sx={{
                    backgroundColor: "F4F4F4",
                    fontSize: "1.9vh",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    color: "#424242",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  SPOT RATE
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.2em",
                    alignItems: "center",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  component="article"
                >
                  <Typography
                    sx={{
                      fontSize: "2.5vw",
                      fontWeight: "bold",
                      backgroundColor: "#424242",
                      color: "#FFFFFF",
                      padding: "1px 6px",
                      borderRadius: "5px",
                      display: "inline-block",
                      position: "relative",
                      bottom: "0.1em",
                      fontFamily: "Roboto",
                      fontWeight: 700,
                    }}
                  >
                    $
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.9vh",
                      fontWeight: "bold",
                      color: "#424242",
                    }}
                  >
                    BID
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.4vh",
                      color: "#424242",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      position: "relative",
                      top: "0.16em",
                    }}
                  >
                    OZ
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "0.2em",
                    alignItems: "center",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  component="article"
                >
                  <Typography
                    sx={{
                      fontSize: "2.5vw",
                      fontFamily: "Roboto",
                      fontWeight: 700,
                      backgroundColor: "#424242",
                      color: "#FFFFFF",
                      padding: "1px 6px",
                      borderRadius: "5px",
                      position: "relative",
                      bottom: "0.1em",
                    }}
                  >
                    {" "}
                    $
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.9vh",
                      fontWeight: "bold",
                      color: "#424242",
                    }}
                  >
                    ASK
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.4vh",
                      color: "#424242",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      position: "relative",
                      top: "0.16em",
                    }}
                  >
                    OZ
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              className={classes.spotPageSpotRateSectionSpotRateBody}
              component="section"
            >
              <Box
                className={classes.spotPageSpotRateSectionSpotRateGold}
                component="article"
              >
                <Typography
                  sx={{
                    fontSize: "3vh",
                    fontWeight: 700,
                    fontFamily: "Roboto",
                    width: "33%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  GOLD
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    //   gap: "10px",
                    width: "66%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "3vh",
                      fontWeight: 900,
                      fontFamily: "Roboto",
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color:
                        gold?.bid < previousGold?.bid
                          ? "#D93C2B"
                          : gold?.bid > previousGold?.bid
                          ? "#0469CA"
                          : "#555555",
                    }}
                  >
                    &nbsp;
                    {(Number(spread.goldBidSpread) + Number(gold.bid)).toFixed(
                      2
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.1em",
                        marginLeft: "-0.5em",
                      }}
                    >
                      <span
                        style={{
                          background: "#D93C2B",
                          padding: "0.2em",
                          fontSize: "1vh",
                          color: "#FFFFFF",
                        }}
                      >
                        LOW
                      </span>
                      <Typography
                        sx={{
                          fontSize: "1.3vh",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                        }}
                      >
                        {(
                          Number(spread.goldLowSpread) + Number(gold.lowPrice)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "3vh",
                      fontWeight: 900,
                      fontFamily: "Roboto",
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color:
                        gold?.ask < previousGold?.ask
                          ? "#D93C2B"
                          : gold?.bid > previousGold?.bid
                          ? "#0469CA"
                          : "#555555",
                    }}
                  >
                    &nbsp;
                    {(Number(spread.goldAskSpread) + Number(gold.ask)).toFixed(
                      2
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.1em",
                        marginLeft: "-0.5em",
                      }}
                    >
                      <span
                        style={{
                          background: "#0469CA",
                          padding: "0.2em",
                          fontSize: "1vh",
                          color: "#FFFFFF",
                          padding: "0.2em",
                        }}
                      >
                        HIGH
                      </span>{" "}
                      <Typography
                        sx={{
                          fontSize: "1.3vh",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                        }}
                      >
                        {(
                          Number(spread.goldHighSpread) + Number(gold.highPrice)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              </Box>

              <Divider />

              <Box
                className={classes.spotPageSpotRateSectionSpotRateSilver}
                component="article"
              >
                <Typography
                  sx={{
                    fontSize: "2.5vh",
                    fontWeight: 500,
                    fontFamily: "Roboto",
                    width: "33%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  SILVER
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    width: "66%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "-0.7em",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2.5vh",
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color:
                        silver?.bid < previousSilver?.bid
                          ? "#D93C2B"
                          : silver?.bid > previousSilver?.bid
                          ? "#0469CA"
                          : "#555555",
                    }}
                  >
                    {(
                      Number(spread.silverAskSpread) + Number(silver.bid)
                    ).toFixed(2)}
                    {/* &nbsp; */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.1em",
                      }}
                    >
                      <span
                        style={{
                          background: "#D93C2B",
                          fontSize: "1vh",
                          color: "#FFFFFF",
                          padding: "0.2em",
                        }}
                      >
                        LOW
                      </span>{" "}
                      <Typography
                        sx={{
                          fontSize: "1.3vh",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                        }}
                      >
                        {(
                          Number(spread.silverLowSpread) +
                          Number(silver.lowPrice)
                        ).toFixed(2)}
                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                      </Typography>
                    </Box>
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "2.5vh",
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color:
                        silver?.ask < previousSilver?.ask
                          ? "#D93C2B"
                          : silver?.ask > previousSilver?.ask
                          ? "#0469CA"
                          : "#555555",
                    }}
                  >
                    {(
                      Number(spread.silverBidSpread) + Number(silver.ask)
                    ).toFixed(2)}
                    {/* &nbsp; */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.1em",
                      }}
                    >
                      <span
                        style={{
                          background: "#0469CA",
                          padding: "0.2em",
                          fontSize: "1vh",
                          color: "#FFFFFF",
                          padding: "0.2em",
                        }}
                      >
                        HIGH
                      </span>{" "}
                      <Typography
                        sx={{
                          fontSize: "1.3vh",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                        }}
                      >
                        {(
                          Number(spread.silverHighSpread) +
                          Number(silver.highPrice)
                        ).toFixed(2)}
                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.spotPageCommodityContainer} component="section">
          <Box className={classes.commodityTable}>
            <Box className={classes.commodityTableHeader}>
              <Typography
                sx={{
                  flex: 1,
                  // width: "33%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontSize: "1.7vh",
                  fontFamily: "Roboto",
                  fontWeight: 700,
                }}
              >
                COMMODITY
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  // width: "33%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.7vh",
                  fontFamily: "Roboto",
                  fontWeight: 700,
                }}
              >
                WEIGHT
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  // width: "33%",
                  display: "flex",
                  justifyContent: "center",

                  alignItems: "center",
                  fontSize: "1.7vh",
                  fontFamily: "Roboto",
                  fontWeight: 700,
                }}
              >
                PRICE
                <Typography
                  sx={{ fontSize: "0.9vh", mt: 0.5, fontWeight: 500 }}
                >
                  &nbsp;AED
                </Typography>
              </Typography>
              {/* <Typography
                  sx={{
                    // flex: 1,
                    // display: "flex",
                    // justifyContent: "left",
                    // // paddingLeft: 4,
                    // alignItems: "center",
                    // justifyContent: "flex-start",
                    // fontSize: "2vh",
                    // fontWeight: "bold",
                    // color:"#424242"
                  }}
                >
                  SELL
                  <Typography sx={{ fontSize: "0.9vw", mt: 1 }}>
                    &nbsp;AED
                  </Typography>
                </Typography> */}
            </Box>
            <Box className={classes.commodityTableContent}>
              {commodities?.map((commodity, idx) => {
                const words = commodity.commodity_title.split(" ");
                // const isEvenRow = idx % 2 === 0;
                return (
                  commodity.commodity_title !== "Minted Bar" &&
                  commodity.commodity_title !== "Gold Coin" &&
                  commodity.commodity_title !== "Gold Kilobar" && (
                    <Box
                      key={idx}
                      className={classes.commodityTableRow}
                      sx={{ boxShadow: "inset 0px -0.5px 0px 0px #FFFFFF" }}
                    >
                      <Typography
                        style={{
                          // fontSize: "2vh",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          paddingLeft: "1em",
                          lineHeight:
                            window.innerHeight < 525
                              ? 1.6
                              : window.innerHeight < 595
                              ? 1.7
                              : 1.9,
                          fontFamily: "Roboto",
                          fontWeight: 400,
                        }}
                        className={classes.commodityTableRowColumn}
                      >
                        {words.map((word, index) => {
                          if (index === 0) {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "2.4vh",
                                  // fontWeight: "bold",
                                  fontFamily: "Roboto",
                                  fontWeight: 400,
                                }}
                              >
                                {word}
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "1vh",
                                  marginTop: "1em",
                                }}
                              >
                                &nbsp;
                                {word === "Kilobar" ||
                                word === "Coin" ||
                                word === "Bar"
                                  ? ""
                                  : word}
                              </span>
                            );
                          }
                        })}
                        &nbsp;
                        <Typography
                          sx={{
                            fontSize: "1.5vh",
                            marginTop: "0.6em",
                            // fontWeight: "bold",
                          }}
                        >
                          {/* {commodity.commodity_title === "Gold TEN TOLA"
                          ? ""
                          : Number(commodity.purity) === 0.9999 &&
                            commodity.unitLabel === "KG"
                          ? 9999
                          : Number(commodity.purity) === 0.9999 &&
                            commodity.unitLabel === "GM"
                          ? "24k"
                          : Number(commodity.purity) * 1000} */}
                          {commodity.commodity_title === "Gold TEN TOLA"
                            ? ""
                            : Number(commodity.purity) === 0.9999
                            ? 9999
                            : Number(commodity.purity) * 1000}
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          // paddingLeft: "1em",
                          fontSize: "2vh",
                          fontFamily: "Roboto",
                          fontWeight: 400,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={classes.commodityTableRowColumn}
                      >
                        {commodity.unit}
                        {commodity.unitLabel}
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          // justifyContent:"space-evenly",
                          // paddingLeft: "0.3em",
                          alignItems: "center",
                          position: "relative",
                          left:
                            window.innerWidth < 360
                              ? "7vw"
                              : window.innerWidth < 390
                              ? "8vw"
                              : window.innerWidth < 460
                              ? "9vw"
                              : "10vw",
                          fontSize: "2vh",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                        }}
                        className={classes.commodityTableRowColumn}
                      >
                        {commodityCalculation(
                          commodity.commodity_title === "Silver"
                            ? silver.ask
                            : gold.ask,
                          commodity.premium,
                          commodity.unitLabel === "KG"
                            ? 1000
                            : commodity.unitLabel === "TTB"
                            ? 116.64
                            : 1,
                          commodity.purity,
                          commodity.charges
                        ).toFixed(
                          commodity.unitLabel === "KG" ||
                            commodity.unitLabel === "TTB"
                            ? 0
                            : 2
                        )}
                      </Typography>
                      {/* <Typography
                        style={{ fontSize: "1.8vw", fontWeight: "bold" }}
                        className={classes.commodityTableRowColumn}
                      >
                        {commodityCalculation(
                          commodity.commodity_title === "Silver"
                            ? silver.ask
                            : gold.ask,
                          commodity.premium,
                          commodity.unitLabel === "KG"
                            ? 1000
                            : commodity.unitLabel === "TTB"
                            ? 116.64
                            : 1,
                          commodity.purity,
                          commodity.charges
                        ).toFixed(
                          commodity.unitLabel === "KG" ||
                            commodity.unitLabel === "TTB"
                            ? 0
                            : 2
                        )}
                      </Typography> */}
                    </Box>
                  )
                );
              })}
            </Box>
          </Box>
        </Box>

        <Box className={classes.spotPagePambBarContainer} component="section">
          <Box className={classes.pambBarTable}>
            <Box className={classes.pambBarTableHeader}>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.7vh",
                  fontFamily: "Roboto",
                  fontWeight: 700,
                }}
              >
                PAMB BARS
              </Typography>
            </Box>
            <Box className={classes.pambBarTableContent}>
              {commodities?.map((commodity, idx) => {
                const words = commodity.commodity_title.split(" ");
                const isEvenRow = idx % 2 === 0;
                return (
                  (commodity.commodity_title === "Minted Bar" ||
                    commodity.commodity_title === "Gold Coin" ||
                    commodity.commodity_title === "Gold Kilobar") && (
                    <Box
                      key={idx}
                      sx={{ boxShadow: "inset 0px -0.5px 0px 0px #FFFFFF" }}
                      className={classes.pambBarTableRow}
                    >
                      <Typography
                        style={{
                          // fontSize:"2vh",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          paddingLeft: "1em",
                          lineHeight:
                            window.innerHeight < 525
                              ? 1.6
                              : window.innerHeight < 595
                              ? 1.7
                              : 1.9,
                          fontFamily: "Roboto",
                          fontWeight: 400,
                        }}
                        className={classes.pambBarTableRowColumn}
                      >
                        {words.map((word, index) => {
                          if (index === 0) {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "2.4vh",
                                  // fontWeight: "bold",
                                  fontFamily: "Roboto",
                                  fontWeight: 400,
                                }}
                              >
                                {word}
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "2.4vh",
                                  // fontWeight: "bold",
                                  fontFamily: "Roboto",
                                  fontWeight: 400,
                                }}
                              >
                                &nbsp;
                                {word === "Kilobar" ||
                                word === "Coin" ||
                                word === "Bar"
                                  ? word
                                  : ""}
                              </span>
                            );
                          }
                        })}
                        &nbsp;
                        {/* <Typography
                        sx={{
                          fontSize: "1.5vh",
                          marginTop: "0.6em",
                          // fontWeight: "bold",
                        }}
                      >
                        {commodity.commodity_title === "Gold TEN TOLA"
                          ? ""
                          : Number(commodity.purity) === 0.9999 &&
                            commodity.unitLabel === "KG"
                          ? 9999
                          : Number(commodity.purity) === 0.9999 &&
                            commodity.unitLabel === "GM"
                          ? "24k"
                          : Number(commodity.purity) * 1000}
                      </Typography> */}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "2vh",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // alignItems: "center",
                          fontFamily: "Roboto",
                          fontWeight: 400,
                        }}
                        className={classes.pambBarTableRowColumn}
                      >
                        {commodity.unit}
                        {commodity.unitLabel}
                      </Typography>
                      <Typography
                        sx={{
                          // paddingLeft: "0.3em",
                          fontSize: "2vh",
                          fontFamily: "Roboto",
                          position: "relative",
                          alignItems: "center",
                          left:
                            window.innerWidth < 360
                              ? "7vw"
                              : window.innerWidth < 390
                              ? "8vw"
                              : window.innerWidth < 460
                              ? "9vw"
                              : "10vw",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                        }}
                        className={classes.pambBarTableRowColumn}
                      >
                        {commodityCalculation(
                          commodity.commodity_title === "Silver"
                            ? silver.ask
                            : gold.ask,
                          commodity.premium,
                          commodity.unitLabel === "KG"
                            ? 1000
                            : commodity.unitLabel === "TTB"
                            ? 116.64
                            : 1,
                          commodity.purity,
                          commodity.charges
                        ).toFixed(
                          commodity.unitLabel === "KG" ||
                            commodity.unitLabel === "TTB"
                            ? 0
                            : 2
                        )}
                      </Typography>
                    </Box>
                  )
                );
              })}
            </Box>
          </Box>

          <Box className={classes.pampBarTableFooter}>
            <Marquee
              // speed={85}
              style={{
                display: "flex",
                alignItems: "center",
                // whiteSpace: "nowrap",
                fontFamily: "Roboto",
                fontStyle: "italic",
                background: "#FFFFFF",
                fontWeight: "400",
                fontSize: "2vh",
                color: "#424242",
                height: "100%",
                // padding: "1em",
                borderRadius: "0 0 10px 10px",
                height: "4vh",
              }}
            >
              {news}
            </Marquee>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SpotPage;
